import { BaseChatInfo } from "@/includes/types/Chat/types";

export default class ChatLicense {

  chat: BaseChatInfo;

	static LICENSE_TYPES: Record<number, string> = {
		0: "starter",
		1: "advanced",
		2: "ultimate",
	};

	static MONTH_COUNT = [ 1, 12 ];

	constructor(chat: BaseChatInfo) {
		this.chat = chat;
	}

	get isLicenseExpired() {
		return !(this.chat.license_left > 0);
	}

	get licenseExpiresAt() {
		return this.chat.license_timestamp;
	}

	get licenseType() {
		return ChatLicense.LICENSE_TYPES[ this.chat.license_type ];
	}

	get expiresSoon() {
		return this.chat.license_left / (3600 * 24) < 7;
	}
}
