import { isEmpty, isUndefined, has }           from "lodash";
import ChatLicense from "./ChatLicense";
import Api         from "./Api";

export default class Payment {
	chat_id                = 0;
	target                 = "";
	tariff                 = "";
	shop                   = "";
	monthCount             = "";
	shopRequiredFieldsData = "";

	/**
	 *
	 * @param chat_id {int}
	 * @param target {string}
	 * @param tariff {string}
	 * @param shop {string}
	 * @param monthCount {int}
	 * @param shopRequiredFieldsData {Object}
	 */
	constructor(chat_id, target, tariff, shop, monthCount, shopRequiredFieldsData) {
		this.chat_id                = chat_id;
		this.target                 = target;
		this.tariff                 = tariff;
		this.shop                   = shop;
		this.shopRequiredFieldsData = shopRequiredFieldsData;
		this.monthCount             = monthCount;
	}

	static get shopsPriorityList() {
		return [ "local", "global", "crypto" ];
	}

	static get shopsGetawayPriorityCodeList() {
		return {
			"local": [ "sberbank_business" ],
		};
	}

	get payment() {
		const payment = {
			chat_id   : this.chat_id,
			target    : this.target,
			tariff    : this.tariff,
			shop      : this.shop,
			monthCount: this.monthCount,
		};

		if(!isEmpty(this.shopRequiredFieldsData)) {
			payment.params = this.shopRequiredFieldsData;
		}

		return payment;
	}

	/**
	 *
	 * @param item
	 * @returns {{}}
	 */
	static getItemCurrencyShopSymbolsList(item) {
		const list = {};

		for(let shopKey in item.shops) {
			if(item.shops.hasOwnProperty(shopKey)) {
				list[ shopKey ] =
					item.shops[ shopKey ]
						.slice()
						.map(val => val.currency_symbol)
						.filter((value, index, self) => self.indexOf(value) === index);
			}
		}

		return list;
	}

	static getItemPriceInfo(item) {
		for(let i = 0; this.shopsPriorityList.length; i++) {
			const priorityShopKey = this.shopsPriorityList[ i ];
			if(!isUndefined(item.shops[ priorityShopKey ])) {
				const shop = item.shops[ priorityShopKey ][ 0 ];

				if(has(item.shops, priorityShopKey)) return {
					price                     : shop.price,
					base_price                : shop.base_price,
					currency_code             : shop.currency_code,
					currency_short_name       : shop.currency_short_name,
					currency_symbol           : shop.currency_symbol,
					currency_name             : shop.currency_name,
					currency_shop_symbols_list: this.getItemCurrencyShopSymbolsList(item),
					currency_shop_default     : priorityShopKey,
				};
			}
		}
	}

	/**
	 *
	 * @param target {string}
	 * @param chat_id {int}
	 * @returns {AxiosPromise<any>}
	 */
	static getPayMethods(target, chat_id) {
		return Api.getPayMethods("tg", {
			chat_id,
			target,
		});
	}

	createPayment(promocode = "") {
		return new Promise((resolve, reject) => {
			const payment = this.payment;
			if(promocode !== "") {
				payment.promocode = promocode;
			}

			Api.createPayment("tg", payment)
				 .then(res => {
					 window.location.href = res.data.url;
					 resolve();
				 })
				 .catch(err => {
					 console.error(err);
					 reject(err);
				 });
		});
	}

	/**
	 *
	 * @param invoice {string}
	 * @returns {AxiosPromise}
	 */
	static getPayInfo(invoice) {
		return Api.getPayInfo("tg", { invoice });
	}

	static sort(shops) {
		return shops.sort((a, b) => {
			return b[ "weight" ] - a[ "weight" ];
		});
	}

	/**
	 *
	 * @param query {Object}
	 * @return {boolean}
	 */
	static isVerifiedChatLicenseBuyQueryParams(query) {
		return Object.values(ChatLicense.LICENSE_TYPES).includes(query.tariff) && Object.values(ChatLicense.MONTH_COUNT).includes(parseInt(query.monthCount));
	}

	static runCoupon(chat_id, target, promocode) {
		return Api.runCoupon("tg", {
			chat_id,
			target,
			promocode,
		});
	}
}
