<template>
  <div class="flex flex-col">
    <a-row class="mb-6">
      <a-col
        :lg="8"
        :sm="24"
        :xs="24"
        class="p-4"
      >
        <a-button
          v-if="currentStepIndex > 0"
          icon="arrow-left"
          @click="stepBack()"
        >
          Назад
        </a-button>
      </a-col>
      <a-col
        :lg="8"
        :sm="24"
        :xs="24"
        class="flex flex-col items-center justify-center p-4"
      >
        <h5 class="font-semibold">
          {{ stepTitle }}
        </h5>
      </a-col>
      <a-col
        :lg="8"
        class="hidden lg:flex"
      />
    </a-row>
    <layout-grid
      v-if="isCreated"
      id="add-chat"
      class="min-h-screen"
    >
      <div class="flex flex-col items-center justify-center h-full">
        <transition
          name="fade"
          mode="out-in"
        >
          <component
            :is="steps[currentStep].view"
            v-bind="steps[currentStep].propsData"
            v-on="steps[currentStep].listeners"
            v-model="steps[currentStep].model"
          />
        </transition>

      </div>
    </layout-grid>
  </div>
</template>

<script>
import Api from '@/includes/logic/Api'
import Payment from "@/includes/logic/Payment";
import { errorNotification } from "@/includes/NotificationService";

import LayoutGrid from 'piramis-base-components/src/components/LayoutGrid.vue'

import { snakeCase } from 'lodash';

// 1610989345909.5f2a0cd713194fa4a86f62f061c48e51

const Step = {
  ChooseLanguage: 'chooseLanguage',
  SelectTariff: 'selectTariff',
  SelectChat: 'selectChat',
  SelectGetaway: 'selectGetaway',
  RequiredFields: 'requiredFields',
}

export default {
  i18n: {
    messages: {
      ru: {
        'add_chat_select_getaway_title': 'Выбор платежной системы',
        'add_chat_select_chat_title': 'Выбор чата',
        'add_chat_select_tariff_title': 'Выбор тарифа',
        'add_chat_choose_language_title': 'Выбор языка',
        'add_chat_required_fields_title': 'Оформление',
      },
      en: {
        'add_chat_select_getaway_title': 'Choosing a payment system',
        'add_chat_select_chat_title': 'Chat selection',
        'add_chat_select_tariff_title': 'Tariff selection',
        'add_chat_choose_language_title': 'Language selection',
        'add_chat_required_fields_title': 'Registration',
      },
    },
  },

  name: "add-chat",

  components: {
    LayoutGrid
  },

  data() {
    return {
      isCreated: false,

      // tariffs: null,
      // available_languages: null,

      // steps: null,

      currentStep: null,
    }
  },

  computed: {
    tariffs() {
      return this.$store.state?.AdminTemplate?.runtime_config && this.$store.state.AdminTemplate.runtime_config.tariff
    },
    available_languages() {
      if (this.$store.state?.AdminTemplate?.runtime_config) {
        return  this.$store.state.AdminTemplate.runtime_config.langs
      }

      return [ 'en', 'ru' ]
    },
    steps() {
      return {
        [Step.ChooseLanguage]: {
          view: () => import(`@/components/chat-adding/SelectLanguage`),
          propsData: {
            languages: this.available_languages,
          },
          listeners: {
            input: () => {
              setTimeout(() => this.moveToNextStep(), 500)
            }
          },
          model: null
        },
        [Step.SelectTariff]: {
          view: () => import(`@/components/chat-adding/SelectTariff`),
          propsData: {
            tariffs: this.tariffs,
          },
          listeners: {
            input: () => {
              setTimeout(() => this.moveToNextStep(), 500)
            }
          },
          model: null
        },
        [Step.SelectChat]: {
          view: () => import(`@/components/chat-adding/SelectChat.vue`),
          propsData: {
            chats: null,
          },
          listeners: {
            input: (chat) => {
              this.steps.selectGetaway.propsData.chat = chat
              setTimeout(() => this.moveToNextStep(), 500)
            },
            addChat: ({ closeLoading }) => {
              Api.createInstallRequest('tg', {
                params: this.collectDataFromSteps(),
                lang: this.steps.chooseLanguage.model
              })
                .then((res) => {
                  const key = res.data.key

                  // console.log(key)

                  Api.getInstallRequestChats('tg', { key })
                    .then((res) => {
                      setTimeout(() => window.location.href = res.data.add_chat_url, 5000)
                    })
                    .catch(errorNotification)
                    .finally(() => closeLoading())
                })
                .catch(errorNotification)
            }
          },
          model: null
        },
        [Step.SelectGetaway]: {
          view: () => import(`@/components/chat-adding/SelectGetaway`),
          propsData: {
            chat: null,
            tariff: null
          },
          listeners: {
            input: (value) => {
              if (value.required) {
                this.steps.requiredFields.propsData.fields = value.required
              } else {
                this.steps.requiredFields.propsData.fields = null
              }

              setTimeout(() => this.moveToNextStep(), 500)
            }
          },
          model: null
        },
        [Step.RequiredFields]: {
          view: () => import(`@/components/chat-adding/RequiredFields`),
          propsData: {
            fields: null
          },
          listeners: {
            input: ({ promocode, requiredFields }) => {
              this.$baseTemplate.loader.open();
              new Payment(
                this.steps[Step.SelectChat].model.chat_id,
                'chat',
                this.steps[Step.SelectTariff].model.name,
                this.steps[Step.SelectGetaway].model.code,
                this.steps[Step.SelectTariff].model.packageCount,
                requiredFields
              )
                .createPayment(promocode)
                .catch(errorNotification)
                .finally(() => {
                  this.$baseTemplate.loader.close();
                });
            }
          },
          model: null
        }
      }
    },
    stepsKeys() {
      if (this.steps) {
        return Object.keys(this.steps)
      }

      return []
    },

    currentStepIndex() {
      return this.stepsKeys.indexOf(this.currentStep)
    },

    stepTitle() {
      if (this.currentStep) {
        return this.$t(`add_chat_${ snakeCase(this.currentStep) }_title`)
      }
      return ''
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next({ name: "chats" })
  },
  methods: {
    stepBack() {
      if (this.currentStepIndex === 0) {
        return
      }

      this.currentStep = this.stepsKeys[this.stepsKeys.indexOf(this.currentStep) - 1]
    },

    moveToNextStep() {
      if (this.currentStepIndex === this.stepsKeys.length - 1) {
        return
      }

      this.currentStep = this.stepsKeys[this.stepsKeys.indexOf(this.currentStep) + 1]
    },

    async getFirstStep() {
      const key = this.$route.params.key

      if (key) {
        const { data } = await Api.getInstallRequestChats('tg', { key })
        Object.keys(data.params).forEach((paramKey) => {
          this.steps[paramKey].model = data.params[paramKey].model
        })

        this.steps[Step.SelectChat].propsData.chats = data.chats

        if (data.chats.length === 1) {
          this.steps[Step.SelectChat].model = data.chats[0]
          this.steps[Step.SelectGetaway].propsData.chat = data.chats[0]
          this.steps[Step.SelectGetaway].propsData.tariff = this.steps[Step.SelectTariff].model
          this.currentStep = Step.SelectGetaway
        } else {
          this.currentStep = Step.SelectChat
        }
      } else {
        this.currentStep = Object.keys(this.steps)[0]
      }

      this.isCreated = true
    },

    collectDataFromSteps() {
      const params = {}
      Object.keys(this.steps).forEach((key) => {
        params[key] = this.steps[key]
      })
      return params
    }
  },

  mounted() {
    // this.tariffs = this.$store.state.AdminTemplate.runtime_config.tariff
    // this.available_languages = this.$store.state.AdminTemplate.runtime_config.langs

    // this.steps =

    this.getFirstStep()
  }
}
</script>

<style scoped>

</style>
